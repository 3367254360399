<template>
<div class="album-page">album</div>
</template>

<script>
export default {
  name: 'album'
}
</script>

<style scoped lang="less">
</style>
